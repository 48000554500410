import 'semantic-ui-css/semantic.min.css'
import './App.css';
import { BrowserRouter } from "react-router-dom";
import AppWrapper from './components/AppWrapper/AppWrapper';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <AppWrapper />
      </div>
    </BrowserRouter>
  );
}

export default App;
