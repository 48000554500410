import { Fragment } from "react";
import { Link } from "react-router-dom";
import Hero from "../Contentblocks/Hero";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMeta,
  CardDescription,
  Container,
  Grid,
  GridRow,
  GridColumn,
  Icon,
  Header
} from 'semantic-ui-react';

function Contact() {

  const content = [
    {
      header: 'We Help Companies and Companions',
      text: 'Peak State Software is about creating and developing a cohesive ecosystem between our client and their business. Our goal is to have our client projects succeed with massive impact on their market to achieve a Peak State in their business. We are your partner to take the journey and create the excellence you and your business deserve. ',
      image: 'images/keyboard2.jpg'
    }
  ]

  return (
    <Fragment>
      <Hero
         backgroundImage='images/iphone.jpg'
         heading='GET IN TOUCH'
         subHeading={`We actually like talking to our clients.`}
      />
      <Container textAlign='justified' fluid>
        <Grid stretched container stackable verticalAlign='middle' padded='vertically' columns={16}>
          <GridRow columns={16}>
            <GridColumn width={16} stackable>
            <Card fluid>
              <CardContent>
                <CardHeader>Contact Us</CardHeader>
                <CardMeta>Reach out via phone or email.</CardMeta>
              </CardContent>
              <CardContent extra>
                <div>
                  <div>
                    <Icon name='phone' size="large" style={{float: 'left', marginRight: '2rem'}}/>
                    <Header as='h3'>512.765.4502</Header>
                  </div>
                  <div>
                    <Icon name='mail' size="large" style={{float: 'left', marginRight: '2rem'}}/>
                    <Header as='h3'>info@peakstatesoftware.com</Header>
                  </div>
                </div>
              </CardContent>
              <CardContent extra>
                <div>
                  <Link to={'mailto:info@peakstatesoftware.com?subject=Mail%20From%20Website'}>
                    <Button color="orange" floated="right">Email Us</Button>
                  </Link>
                </div>
              </CardContent>
            </Card>
            </GridColumn>
          </GridRow>
        </Grid>
      </Container>
    </Fragment>
  )
}

export default Contact;