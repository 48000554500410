import { Fragment } from "react";
import TwoColumnTextAndImage from "../Contentblocks/TwoColumnTextAndImage";
import Hero from "../Contentblocks/Hero";

function Services() {

  const content = [
    {
      image: 'images/web-dev-1.jpg',
      header: 'Web Development',
      text: 'At Peak State Software, we specialize in crafting bespoke digital solutions tailored to meet the unique needs of your business. With a team of seasoned developers and designers, we excel in creating dynamic and user-friendly websites that captivate audiences and drive results. From sleek e-commerce platforms to engaging corporate portals, we leverage the latest technologies and best practices to bring your vision to life. Whether you\'re a startup or a seasoned enterprise, let us propel your online presence to new heights. Elevate your web experience with us today.',
    },
    {
      image: 'images/sw-eng-4.jpg',
      header: 'Software Engineering',
      text: 'At Peak State Software, we\'re dedicated to engineering innovative software solutions that empower businesses to thrive in the digital age. With a talented team of software architects, developers, and engineers, we specialize in building robust and scalable applications tailored to meet the diverse needs of our clients. From custom software development to full-stack solutions, we leverage cutting-edge technologies and agile methodologies to deliver exceptional results on time and within budget. Partner with Peak State and unlock the full potential of your software vision.',
    },
    {
      image: 'images/e-comm-2.jpg',
      header: 'E-Commerce Solutions',
      text: 'At Peak State Software, we specialize in crafting visually stunning and highly functional online stores that drive conversion and elevate brand presence. With a meticulous eye for detail and a deep understanding of user experience principles, our team of designers and developers work collaboratively to create custom ecommerce solutions tailored to your unique business goals. From intuitive navigation to seamless checkout experiences, we ensure that every aspect of your online store is optimized for success. Whether you\'re launching a new brand or revitalizing an existing one, trust us to deliver ecommerce designs that captivate audiences and maximize sales.',
    },
    {
      image: 'images/graphic-design-3.jpg',
      header: 'Graphic Design',
      text: 'At Peak State Software, we\'re passionate about translating your ideas into captivating visual stories. We offer a full suite of creative services tailored to meet the diverse needs of our clients. From branding and logo design to marketing collateral and digital assets, our team of skilled designers brings your vision to life with precision and flair. Whether you\'re a startup establishing your brand identity or seeking to refresh your established visual presence, we work closely with you to deliver designs that resonate with your audience and leave a lasting impression. Elevate your brand with Peak State\'s innovative graphic design solutions.',
    }
  ]

  const contentBlocks = content.map((block, index) => {
    return <TwoColumnTextAndImage content={block} imageFirst={index % 2 === 0} key={`services-${index}`} />
  })

  return (
    <Fragment>
      <Hero
        backgroundImage='images/graphic-design-2b.jpg'
        heading={'Solutions for Tomorrow: Our Services'.toUpperCase()}
        subHeading={`Empowering Your Business with Cutting-Edge Solutions.`}
      />
      { contentBlocks }
    </Fragment>
  )
}

export default Services;