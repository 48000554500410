import { Fragment } from "react";
import TwoColumnTextAndImage from "../Contentblocks/TwoColumnTextAndImage";
import Hero from "../Contentblocks/Hero";

function Home() {

  const contentBlocks = [
    {
      header: 'We Help Companies and Companions',
      text: 'Peak State Software is about creating and developing a cohesive ecosystem between our client and their business. Our goal is to have our client projects succeed with massive impact on their market to achieve a Peak State in their business. We are your partner to take the journey and create the excellence you and your business deserve. ',
      image: 'images/keyboard2.jpg'
    },
    {
      header: 'How we do it',
      text: 'We welcome each opportunity and look at areas to optimize, and create a vision with our clients that embodies their field. We take “good” and make it GREAT! From Great to AWESOME, and we’ll be there with you each step of the way for you and your business to have a Rock Star product that is your avenue to greater success!',
      image: 'images/idea-blackboard.jpg'
    }
  ]
  
  const content = contentBlocks.map((block, index) => {
    return <TwoColumnTextAndImage content={block} imageFirst={index % 2 === 0} />
  })

  return (
    <Fragment>
      <Hero
        backgroundImage='images/idea-papers.jpg'
        heading='MAKE EVERYTHING OUTSTANDING'
        subHeading={`Otherwise it's not really worth doing.`}
      />
      {content}
    </Fragment>
  )
}

export default Home;