import { Grid, Image, Button, Segment, Header, GridRow, GridColumn, Container } from 'semantic-ui-react'

function TwoColumnTextAndImage({content, imageFirst, imageSource}) {
  const image = content.image ? content.image : 'https://picsum.photos/300/300';
  const sectionButton = () => {
    <Grid.Row>
      <Grid.Column textAlign='center'>
        <Button size='huge'>Check Them Out</Button>
      </Grid.Column>
    </Grid.Row>
  }

  return (
    <Segment style={{ padding: '6em 0em' }} vertical >
      <Grid stretched container stackable verticalAlign='middle' columns={16}>
        <GridRow style={{ flexDirection: imageFirst ? 'row-reverse' : 'row', margin: '0' }} columns={16}>
          <GridColumn width={7} mobile={100}>
            <Header as='h3' style={{ fontSize: '2em' }}>{ content.header }</Header>
            <Container textAlign='justified'>
              <p style={{ fontSize: '1.33em' }}>{ content.text }</p>
            </Container>
          </GridColumn>
          <GridColumn style={{ alignItems: imageFirst ? 'start' : 'end'}} width={8}>
            <Image bordered rounded size='big' src={image} floated={imageFirst ? 'left' : 'right'} stretched />
          </GridColumn>
        </GridRow>
        {sectionButton}
      </Grid>
    </Segment>
  )
}

export default TwoColumnTextAndImage;