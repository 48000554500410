import { Button, Segment, Header, Icon, Container } from 'semantic-ui-react'


function Hero({backgroundImage, heading, subHeading, buttonText, buttonLink, mobile}) {

  const hasLinebreak = (str) => str.includes('\n') ? str.split('\n').map(sub => <h2>{sub}</h2>) : str;

  const HeroHeading = ({ mobile }) => (
    <Container fluid>
      <Header
        as='h1'
        content={heading}
        inverted
        style={{
          fontSize: mobile ? '2em' : '3.5em',
          fontWeight: 'normal',
          marginBottom: 0,
          marginTop: mobile ? '1.5em' : '2.5em',
        }}
      />
      <Header
        as='h2'
        content={hasLinebreak(subHeading)}
        inverted
        style={{
          fontSize: mobile ? '1.5em' : '1.7em',
          fontWeight: 'normal',
          marginTop: mobile ? '0.5em' : '1.5em',
        }}
      />
      {buttonText &&
        <Button
        inverted
        size='huge'
        color='grey'
        style={{
          marginTop: mobile ? '0.5em' : '1.5em',
        }}>
        {buttonText}
        <Icon name='right arrow' />
      </Button>
      }
    </Container>
  )

  return (
    <Segment
      textAlign='center'
      style={{
        minHeight: 600,
        background: `linear-gradient(to top, #000000aa, #00000000), url(${backgroundImage}) no-repeat center center`,
        backgroundSize: 'cover'
      }}
      vertical
      id='image-hero'
    >
    <HeroHeading />
    </Segment>
  )
}

export default Hero;