import { Fragment, useState } from "react";
import Hero from "../Contentblocks/Hero";
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardGroup,
  CardMeta,
  Container,
  Grid,
  GridRow,
  GridColumn,
  Icon,
  Image,
  Modal,
  ModalHeader,
  ModalContent,
  ModalDescription,
  Header,
  ModalActions
} from 'semantic-ui-react';

// project images
import hoodooImg from './projectImages/hoodoo.png';
import thinkImg from './projectImages/thinkSurgical.jpg';
import gvImg from './projectImages/gVape.jpg';
import xcmImg from './projectImages/xcm.jpg';
import jiveImg from './projectImages/jive.jpg';
import leanInImg from './projectImages/leanInLatinas.png'
import bayFootballImg from './projectImages/bayFootball.png'
import qumaniImg from './projectImages/qumani.png';
// tech stack icons
import angularSvg from './icons/Angular.svg';
import html5Svg from './icons/HTML5.svg';
import css3Svg from './icons/CSS3.svg';
import jsSvg from './icons/Javascript.svg';
import reactSvg from './icons/React.svg';
import magentoSvg from './icons/Magento.svg';
import responsiveSvg from './icons/Responsive.svg';
import bootstrapSvg from './icons/Bootstrap.svg';
import wordpressSvg from './icons/Wordpress.svg';
import materialSvg from './icons/Material.svg';

const projects = [
  {
    title: 'Hoodoo Inc',
    subTitle: 'Website',
    image: hoodooImg,
    text: 'Website for a web deveopment and graphic design company. It utilizes AngularJS, HTML5, CSS3, Javascript, and Bootstrap.',
    stack: ['react','html5', 'css3', 'js', 'material']
  },
  {
    title: 'Lean In Latinas',
    subTitle: 'Website',
    image: leanInImg,
    text: 'Website for a LEAN IN group based in Silicon Valley. It utilizes Wordpress, AngularJS, HTML5, CSS3, Material Design',
    stack: ['react','html5', 'css3', 'js', 'material']
  },
  {
    title: 'Think Surgical',
    subTitle: 'Website',
    image: thinkImg,
    text: 'Website for a company that specialized in surgical robots. It utilizes AngularJS, HTML5, CSS3, Bootstrap',
    stack: ['angular','html5', 'css3', 'js', 'bootstrap']
  },
  {
    title: 'Xerox Content MGMT',
    subTitle: 'Website',
    image: xcmImg,
    text: 'Website created for Xerox Content Management, a cloud storage solution developed by Xerox. It utilized Wordpress, HTML5, CSS3, Bootstrap',
    stack: ['angular','html5', 'css3', 'js']
  },
  {
    title: 'Jive Software',
    subTitle: 'Website',
    image: jiveImg,
    text: 'Website rebrand built with Headless Wordpress, HTML5, CSS3, Bootstrap',
    stack: ['html5', 'css3', 'js', 'wordpress', 'bootstrap']
  },
  {
    title: 'Qumani Designs',
    subTitle: 'Website',
    image: qumaniImg,
    text: 'Website for the purpose of doing freelance web development and graphic design',
    stack: ['html5', 'css3', 'js', 'wordpress', 'bootstrap']
  },
  {
    title: 'Bay Area Youth',
    subTitle: 'Website',
    image: bayFootballImg,
    text: 'Website built for a youth flag football league in San Jose California',
    stack: ['html5', 'css3', 'js', 'wordpress', 'bootstrap']
  },
  {
    title: 'Guerrilla Vape',
    subTitle: 'Ecommerce Website',
    image: gvImg,
    text: 'Storefront built to sell organic nicotine "vape fluid". It was built with Magenta, HTML5, CSS3, Bootstrap',
    stack: ['html5', 'css3', 'js', 'magento', 'bootstrap']
  },
]

// places icon for associated tech stack item where used
const TechStackIcon = (icon) => {
  let fetchIcon = () => {
    switch (true) {
      case icon === 'angular':
        return [angularSvg, 'angular'];
      case icon === 'html5':
        return [html5Svg, 'html5'];
      case icon === 'css3':
        return [css3Svg, 'css3'];
      case icon === 'js':
        return [jsSvg, 'javascript'];
      case icon === 'react':
        return [reactSvg, 'react'];
      case icon === 'magento':
        return [magentoSvg, 'magento']
      case icon === 'bootstrap':
        return [bootstrapSvg, 'bootstrap']
      case icon === 'responsive':
        return [responsiveSvg, 'responsive design']
      case icon === 'wordpress':
        return [wordpressSvg, 'wordpress']
      case icon === 'material':
        return [materialSvg, 'material']
      default:
        break;
    }
  }

  let iconImage = fetchIcon();

  return (
    <i className="icon" style={{paddingRight: '10px'}}>
      <img width={24} height={24} src={iconImage[0]} alt={iconImage[1]} />
    </i>
  )
}

function ProjectModal(project, index) {
  const [open, setOpen] = useState(false)

  return (
    <Modal
      id={`project-${index}-modal`}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button style={{float: 'right'}}>View</Button>}
    >
      <ModalHeader>{project.title}</ModalHeader>
      <ModalContent image>
        <Image size='huge' src={project.image} wrapped />
      </ModalContent>
      <ModalContent>
      <ModalDescription>
          <Header>{project.subTitle}</Header>
          <p>{project.text}</p>
        </ModalDescription>
      </ModalContent>
      <ModalActions style={{display: 'flex', alignItems: 'center'}}>
      <div className="modal-extra-left" style={{width: '50%', display: 'flex', justifyContent: 'flex-start'}}>
        {project.stack.map((tech, index) => {
          return <Icon style={{marginRight: '10px'}}>{TechStackIcon(tech.toString())}</Icon>;
        })}
      </div>
      <div className="modal-extra-right" style={{width: '50%'}}>
        <Button
          color='black'
          data-model={`project-${index}-modal`}
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
      </div>        
      </ModalActions>
    </Modal>
  )
}
 
function Portfolio() {
  const projectCards = projects.map((data, index) => {
    return  (
      <Card key={`project-${index + 1}`}>
        <Image src={data.image} wrapped ui={false} />
        <CardContent>
          <CardHeader>{data.title}</CardHeader>
          <CardMeta>
            <span className='date'>{data.subTitle}</span>
          </CardMeta>
          <CardDescription style={{minHeight: '5rem'}}>
            {data.text}
          </CardDescription>
        </CardContent>
        <CardContent extra style={{display: 'flex', alignItems: 'center'}}>
          <div className="card-extra-left" style={{width: '65%'}}>
            {data.stack.map((tech, index) => {
              return <Icon style={{marginRight: '10px'}}>{TechStackIcon(tech.toString())}</Icon>;
            })}
          </div>
          <div className="card-extra-right" style={{width: '35%'}}>
            {ProjectModal(data, index)}
          </div>
        </CardContent>
      </Card>
    )
  })


  return (
    <Fragment >
      <Hero
        backgroundImage='images/guy-working.jpg'
        heading='PROJECTS'
        subHeading={`There are three responses to a piece of design - yes, no, and WOW!\nWow is the one to aim for.`}
      />
      <Container textAlign='justified' fluid>
      <Grid stretched container stackable verticalAlign='middle' padded='vertically' columns={16}>
        <GridRow columns={16}>
          <GridColumn width={16} stackable>
            <CardGroup itemsPerRow={4} doubling stackable>
              {projectCards}
            </CardGroup>
          </GridColumn>
        </GridRow>
      </Grid>
      </Container>
    </Fragment>
  )
}

export default Portfolio;